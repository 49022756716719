// Extracted from customizations done directly to third party code,
// see a14354425d8a20799e8e7f6987f6041dde066862 for original repo entry.
//
// fix for duplicate names from
// http://www.codeboss.in/web-funda/2009/05/27/jquery-validation-for-array-of-input-elements/
$.extend($.validator.prototype, {
	checkForm: function () {
		this.prepareForm();
		for (
			var i = 0, elements = (this.currentElements = this.elements());
			elements[i];
			i++
		) {
			if (
				this.findByName(elements[i].name).length != undefined &&
				this.findByName(elements[i].name).length > 1
			) {
				for (
					var cnt = 0;
					cnt < this.findByName(elements[i].name).length;
					cnt++
				) {
					this.check(this.findByName(elements[i].name)[cnt]);
				}
			} else {
				this.check(elements[i]);
			}
		}
		return this.valid();
	}
});
